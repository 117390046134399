import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-simple-flex-grid'
import classNames from 'classnames'
import { graphql } from 'gatsby'

import { AppContext } from '_context'
import { Container, Heading, Image, Text } from '_atoms'
import { SEO } from '_organisms'
import { CaseStudies, Hero } from '_molecules'
import { Layout } from '_templates'
import variables from '_config/css-variables'
import productIcon from '_images/pages/services/product.svg'
import designIcon from '_images/pages/services/design.svg'
import designMobileIcon from '_images/pages/services/design-mobile.svg'
import mobileIcon from '_images/pages/services/mobile.svg'
import webIcon from '_images/pages/services/web.svg'
import nextgenIcon from '_images/pages/services/nextgen.svg'

import styles from './styles.module.css'

const ServicePage = ({ data }) => {
  const { setNavbarColor } = useContext(AppContext)
  const { servicesTitle, servicesDescription, servicesThumbnail } = data.contentfulPageMetatags

  useEffect(() => {
    const handleScroll = () => {
      const nexgenSectionDOM = document.getElementById('nexgen-section')
      return setNavbarColor(
        variables[
          window.scrollY > nexgenSectionDOM.offsetTop &&
          window.scrollY <= nexgenSectionDOM.offsetTop + nexgenSectionDOM.offsetHeight
            ? 'color-dark-bg'
            : 'color-primary'
        ]
      )
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setNavbarColor])

  return (
    <Layout>
      <SEO
        title={servicesTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={servicesDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={servicesThumbnail.file.url}
      />

      <Hero
        title="services.hero.title"
        subTitle="services.hero.subtitle"
        buttonText="services.hero.button"
        buttonLink="/contact"
        buttonClass="servicesLetsChatButton"
        hasScrollArrow
      />

      <section className={styles.textSection}>
        <Container>
          <Row className={styles.productRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.illustrationLeft}>
              <Image
                src={productIcon}
                alt="Product"
                className={styles.illustration}
                width="569"
                height="452"
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.contentRight}>
              <Heading type="h2" bold className={styles.heading}>
                <FormattedMessage id="services.product.title" />
                <span className={styles.squared}>.</span>
              </Heading>

              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.product.text1" />
              </Text>

              <ul className={styles.list}>
                <li>
                  <FormattedMessage id="services.product.list1" />
                </li>
                <li>
                  <FormattedMessage id="services.product.list2" />
                </li>
                <li>
                  <FormattedMessage id="services.product.list3" />
                </li>
                <li>
                  <FormattedMessage id="services.product.list4" />
                </li>
                <li>
                  <FormattedMessage id="services.product.list5" />
                </li>
                <li>
                  <FormattedMessage id="services.product.list6" />
                </li>
              </ul>
            </Col>
          </Row>

          <Row className={styles.designRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.contentLeft}>
              <Heading type="h2" bold className={styles.heading}>
                <FormattedMessage id="services.design.title" />
                <span className={styles.squared}>.</span>
              </Heading>

              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.design.text1" />
              </Text>
              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.design.text2" />
              </Text>

              <ul className={styles.list}>
                <li>
                  <FormattedMessage id="services.design.list1" />
                </li>
                <li>
                  <FormattedMessage id="services.design.list2" />
                </li>
                <li>
                  <FormattedMessage id="services.design.list3" />
                </li>
                <li>
                  <FormattedMessage id="services.design.list4" />
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.illustrationRight}>
              <Image
                src={designIcon}
                alt="Design"
                className={styles.illustration}
                width="546"
                height="473"
              />
              <Image
                src={designMobileIcon}
                alt="Design"
                className={classNames(styles.illustration, styles.illustrationMobile)}
                width="145"
                height="129"
              />
            </Col>
          </Row>

          <Row className={styles.mobileRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.illustrationLeft}>
              <Image
                src={mobileIcon}
                alt="Mobile"
                className={styles.illustration}
                width="469"
                height="509"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.contentRight}>
              <Heading type="h2" bold className={styles.heading}>
                <FormattedMessage id="services.mobile.title" />
                <span className={styles.squared}>.</span>
              </Heading>

              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.mobile.text1" />
              </Text>
              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.mobile.text2" />
              </Text>

              <ul className={styles.list}>
                <li>
                  <FormattedMessage id="services.mobile.list1" />
                </li>
                <li>
                  <FormattedMessage id="services.mobile.list2" />
                </li>
                <li>
                  <FormattedMessage id="services.mobile.list3" />
                </li>
                <li>
                  <FormattedMessage id="services.mobile.list4" />
                </li>
              </ul>
            </Col>
          </Row>

          <Row className={styles.webRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.contentLeft}>
              <Heading type="h2" bold className={styles.heading}>
                <FormattedMessage id="services.web.title" />
                <span className={styles.squared}>.</span>
              </Heading>

              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.web.text1" />
              </Text>
              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.web.text2" />
              </Text>

              <ul className={styles.list}>
                <li>
                  <FormattedMessage id="services.web.list1" />
                </li>
                <li>
                  <FormattedMessage id="services.web.list2" />
                </li>
                <li>
                  <FormattedMessage id="services.web.list3" />
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.illustrationRight}>
              <Image
                src={webIcon}
                alt="Web"
                className={styles.illustration}
                width="566"
                height="499"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="nexgen-section" className={styles.nextgen}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.illustrationLeft}>
              <Image
                src={nextgenIcon}
                alt="Next Gen"
                className={styles.illustration}
                width="566"
                height="566"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.contentRight}>
              <Heading type="h2" color="white" bold className={styles.heading}>
                <FormattedMessage id="services.nextgen.title" />
                <span className={styles.squared}>.</span>
              </Heading>

              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.nextgen.text1" />
              </Text>
              <Text color="dark" className={styles.text}>
                <FormattedMessage id="services.nextgen.text2" />
              </Text>

              <ul className={styles.list}>
                <li>
                  <FormattedMessage id="services.nextgen.list1" />
                </li>
                <li>
                  <FormattedMessage id="services.nextgen.list2" />
                </li>
                <li>
                  <FormattedMessage id="services.nextgen.list3" />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <CaseStudies className={styles.cta} linkId="servicesCaseStudiesLink" />
    </Layout>
  )
}

export const query = graphql`
  query ServicesPageContent($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      servicesDescription
      servicesTitle
      servicesThumbnail {
        file {
          url
        }
      }
    }
  }
`

ServicePage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default ServicePage
